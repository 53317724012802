import React, { useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import { Notification } from '@/components/partials/notification/Notification';
import { isEmail, useForm } from '@mantine/form';
import { LoadingOverlay, TextInput } from '@mantine/core';
import { roleToContentPath, roleToAppPath } from '@/utils/utilities';
import { useContent } from '@/providers/ContentProvider';
import { Role } from '@/types/user';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import LogoNPB from '@/assets/images/logo.svg';
import Icons from '@/assets/Icons';
import styles from './ResetPassword.module.scss';

const initialValues = {
	email: '',
};

interface Props {
	role: Role;
}

export const ResetPassword: React.FC<Props> = ({ role }) => {
	const { getText, getContent, getErrorMessage } = useContent();

	const baseSlug = `${roleToContentPath(role)}_resetPassword_`;

	const [submited, setSubmited] = useState(false);
	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues,
		validate: {
			email: isEmail(getText(`${baseSlug}badEmail`)),
		},
	});

	const handleSubmit = async () => {
		setLoading(true);
		const response = await UnauthenticatedApi.requestResetPassword(
			form.values.email,
			roleToAppPath(role)
		);
		form.reset();
		setLoading(false);

		if (!response)
			return form.setFieldError('email', getText(`${baseSlug}genericError`));

		if (response?.status === 201) return setSubmited(true);
		form.setFieldError(
			'email',
			getErrorMessage(response.data.insideExceptionCode)
		);
	};

	return (
		<form className={styles.form} onSubmit={form.onSubmit(handleSubmit)}>
			<LoadingOverlay visible={loading} />
			<img src={LogoNPB} alt="NPB logo" />
			<h1>{getText(`${baseSlug}header`)}</h1>
			{submited ? (
				<Notification
					theme="informative"
					content={<p>{getText(`${baseSlug}messageSent`)}</p>}
					icon={Icons.mail}
				/>
			) : (
				<>
					<p>{getText(`${baseSlug}description`)}</p>
					<label htmlFor="email">{getText(`${baseSlug}emailLabel`)}</label>
					<TextInput
						{...form.getInputProps('email')}
						id="email"
						classNames={{
							root: styles.input,
						}}
						type="email"
						required
						placeholder={getText(`${baseSlug}emailPlaceholder`)}
					/>
					<Button
						themes={['blue', 'arrow']}
						buttonProps={{
							type: 'submit',
						}}
					>
						<p>{getText(`${baseSlug}resetButton`)}</p>
					</Button>
				</>
			)}
			{getContent(`${baseSlug}information`, {
				$login: `/${roleToAppPath(role)}/login`,
			})}
		</form>
	);
};
