import { ReactNode } from 'react';
import {
	MantineProvider as Mantine,
	MantineThemeOverride,
	rem,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { useMediaQuery } from '@mantine/hooks';
import APP_CONFIG from '@/configs/appConfig';
import Icons from '@/assets/Icons';
import mantineStyles from '../sass/mantine.module.scss';
import 'dayjs/locale/pl';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';

export const MantineProvider = ({ children }: { children: ReactNode }) => {
	const mobile = useMediaQuery(
		`(max-width: ${APP_CONFIG.BREAKPOINTS.MOBILE}px)`
	);

	const colors: MantineThemeOverride['colors'] = {
		blue: [
			'#eff6ff',
			'#dbeafe',
			'#bfdbfe',
			'#60a5fa',
			'#3b82f6',
			'#2563eb',
			'#1d4ed8',
			'#1e5fa7',
			'#2e417f',
			'#1e3a8a',
		],
		gray: [
			'#f8fafc',
			'#f1f5f9',
			'#e2e8f0',
			'#cbd5e1',
			'#94a3b8',
			'#64748b',
			'#475569',
			'#334155',
			'#334155',
			'#334155',
		],
		green: [
			'#f0fdf4',
			'#bbf7d0',
			'#16a34a',
			'#15803d',
			'#15803d',
			'#15803d',
			'#15803d',
			'#15803d',
			'#15803d',
			'#15803d',
		],
		orange: [
			'#fff7ed',
			'#fed7aa',
			'#fb923c',
			'#ea580c',
			'#ea580c',
			'#ea580c',
			'#ea580c',
			'#ea580c',
			'#ea580c',
			'#ea580c',
		],
		red: [
			'#fef2f2',
			'#fecaca',
			'#f87171',
			'#ef4444',
			'#dc2626',
			'#b91c1c',
			'#b91c1c',
			'#b91c1c',
			'#b91c1c',
			'#b91c1c',
		],
		teal: [
			'#f0fdfa',
			'#14b8a6',
			'#0d9488',
			'#0f766e',
			'#0f766e',
			'#0f766e',
			'#0f766e',
			'#0f766e',
			'#0f766e',
			'#0f766e',
		],
	};

	const components: MantineThemeOverride['components'] = {
		Pagination: {
			defaultProps: {
				withControls: true,
				mt: 14,
				sx: {
					justifyContent: mobile ? 'center' : 'flex-end',
				},
			},
			classNames: {
				control: mantineStyles.paginationControl,
				dots: mantineStyles.paginationDots,
			},
		},
		Modal: {
			defaultProps: {
				centered: true,
				transitionProps: {
					transition: 'slide-down',
				},
				withCloseButton: false,
				padding: 'lg',
				size: 'auto',
				zIndex: APP_CONFIG.Z_INDEX.MODAL,
			},
			classNames: {
				close: mantineStyles.modalClose,
			},
		},
		Menu: {
			defaultProps: {
				position: 'bottom-end',
				shadow: 'sm',
				transitionProps: {
					transition: 'pop-top-right',
				},
			},
			classNames: {
				itemLabel: mantineStyles.menuItemLabel,
				itemIcon: mantineStyles.menuItemIcon,
			},
		},
		Collapse: {
			defaultProps: {
				animateOpacity: true,
			},
		},
		Divider: {
			defaultProps: ({ colors }) => ({
				color: colors.gray[2],
			}),
		},
		Select: {
			defaultProps: {
				rightSection: Icons.arrowDown,
				transitionProps: {
					transition: 'scale-y',
					duration: 100,
					timingFunction: 'ease',
				},
			},
			classNames: {
				input: mantineStyles.selectInput,
				rightSection: mantineStyles.selectRightSection,
				item: mantineStyles.selectItem,
				nothingFound: mantineStyles.selectNothingFound,
				disabled: mantineStyles.selectDisabled,
				invalid: mantineStyles.selectInvalid,
			},
		},
		NativeSelect: {
			defaultProps: {
				rightSection: Icons.arrowDown,
			},
			classNames: {
				input: mantineStyles.nativeSelectInput,
			},
		},
		ScrollArea: {
			defaultProps: {
				scrollbarSize: 8,
				scrollHideDelay: 500,
			},
			classNames: {
				scrollbar: mantineStyles.scrollAreaScrollbar,
				thumb: mantineStyles.scrollAreaThumb,
			},
		},
		Tooltip: {
			defaultProps: ({ colors }) => ({
				position: 'top',
				transitionProps: {
					transition: 'pop',
					duration: 200,
				},
				multiline: true,
				arrowSize: 8,
				events: { hover: true, focus: false, touch: true },
				color: colors.gray[1],
				radius: 6,
			}),
			classNames: {
				tooltip: mantineStyles.tooltipTooltip,
			},
		},
		TooltipFloating: {
			defaultProps: ({ colors }) => ({
				color: colors.red[0],
				position: 'top',
			}),
			classNames: {
				tooltip: mantineStyles.tooltipTooltipFloating,
			},
		},
		Group: {
			classNames: {
				root: mantineStyles.groupRoot,
			},
		},
		Radio: {
			classNames: {
				body: mantineStyles.radioBody,
				label: mantineStyles.radioLabel,
				inner: mantineStyles.radioInner,
				radio: mantineStyles.radioRadio,
				icon: mantineStyles.radioIcon,
			},
		},
		Stack: {
			classNames: {
				root: mantineStyles.stackRoot,
			},
		},
		Checkbox: {
			classNames: {
				body: mantineStyles.checkboxBody,
				label: mantineStyles.checkboxLabel,
				input: mantineStyles.checkboxInput,
				icon: mantineStyles.checkboxIcon,
				inner: mantineStyles.checkboxInner,
			},
		},
		TextInput: {
			defaultProps: {
				input: {
					mb: 0,
				},
			},
			classNames: {
				error: mantineStyles.textInputError,
				input: mantineStyles.textInputInput,
				rightSection: mantineStyles.textInputSection,
			},
		},
		LoadingOverlay: {
			defaultProps: ({ colors }) => ({
				overlayOpacity: 0.5,
				zIndex: APP_CONFIG.Z_INDEX.OVERLAY,
				overlayColor: colors.gray[0],
			}),
		},
		Overlay: {
			defaultProps: ({ colors }) => ({
				radius: 'xs',
				zIndex: APP_CONFIG.Z_INDEX.OVERLAY,
				color: colors.gray[0],
			}),
		},
		Slider: {
			defaultProps: ({ colors }) => ({
				color: colors.blue[5],
				labelTransition: 'fade',
				labelTransitionDuration: 250,
			}),
		},
		RangeSlider: {
			defaultProps: ({ colors }) => ({
				color: colors.blue[5],
				labelTransition: 'fade',
				minRange: 1,
				labelTransitionDuration: 250,
			}),
		},
		Timeline: {
			defaultProps: {
				bulletSize: 30,
				lineWidth: 3,
			},
			classNames: {
				itemTitle: mantineStyles.timelineItemTitle,
				itemBullet: mantineStyles.timelineItemBullet,
			},
		},
		Calendar: {
			defaultProps: {
				locale: 'pl',
			},
		},
		Highlight: {
			defaultProps: ({ colors }) => ({
				highlightColor: colors.blue[1],
				highlightStyles: {
					fontWeight: 700,
					color: colors.blue[7],
				},
			}),
		},
		TimeInput: {
			defaultProps: {},
			classNames: {
				label: mantineStyles.timeInputLabel,
				input: mantineStyles.timeInputInput,
			},
		},
		Popover: {
			defaultProps: {
				withArrow: true,
				arrowSize: 10,
				shadow: 'md',
			},
		},
	};

	return (
		<Mantine
			theme={{
				fontFamily: 'Inter, sans-serif',
				black: colors.gray ? colors.gray[7] : '',
				cursorType: 'pointer',
				colors,
				components,
				colorScheme: 'light',
				primaryColor: 'blue',
				primaryShade: 7,
				transitionTimingFunction: 'ease-in-out',
				loader: 'oval',
				globalStyles: () => ({
					fontSize: 14,
				}),
			}}
		>
			<ModalsProvider>{children}</ModalsProvider>
		</Mantine>
	);
};
