export const QueryKey = {
	conversations: {
		conversations: 'conversations',
		history: 'conversation_history',
		count: 'conversations_count',
	},
	feedback: 'feedback',
	feedbackTypes: 'feedback_types',
	feasibility: 'feasibility',
	researches: {
		researches: 'research',
		statistics: 'research_statistics',
		generatedLinks: 'generated_links',
		targetGroupsCorrect: 'targetGroups_correct',
		history: 'research_history',
	},
	panelists: {
		panelists: 'panelists',
		minimal: 'minimal',
		transactions: 'panelist_transactions',
	},
	clients: 'clients',
	invoices: 'invoices',
	preQuestionnaires: {
		templates: 'pre_questionnaire_module_templates',
		preQuestionnaires: 'pre_questionnaire_modules',
		defaultPreQuestionnaireId: 'default_pre_questionnaire_id',
	},
	globalSettings: 'global_settings',
	globalSettingsReferral: 'global_settings/referral',
	questionnaires: 'questionnaires',
	regions: 'regions',
	affiliations: 'affiliations',
	pollsters: 'pollsters',
	contests: 'contests',
} as const;
