import { Conversation } from '@/api/ConversationApi';

export enum Role {
	POLLSTER = 'ROLE_POLLSTER',
	PANELIST = 'ROLE_PANELIST',
	CLIENT = 'ROLE_CLIENT',
}

export enum UserStatus {
	INACTIVE,
	ACTIVE,
	SUSPENDED,
}

export interface BaseUser {
	id: string;
	email: string;
	firstName?: string;
	lastName?: string;
	password?: string;
	phone?: string;
	status: UserStatus;
	hasOwnPassword: boolean;
}

export interface Address {
	postalCode?: string;
	city?: string;
}

export interface Client extends BaseUser, Address {
	address?: string;
	companyName: string;
	role: Role.CLIENT;
	vatNumber: string;
	priceModifier: number;
	referralCode: string;
}

export interface Panelist extends BaseUser, Address {
	lastActiveAt: string;
	totalPayouts?: number;
	accountNumber?: string;
	preQuestionnaireProgress: number;
	defaultQuestionnaireProgress: number;
	role: Role.PANELIST;
	currentConversation?: Conversation;
	isDefaultFilled: boolean;
	province?: string;
	balance: number;
	moneyBalance: number;
	county?: string;
	birthDate?: string;
	blockedTo?: string | null;
	referralCode: string;
	notificationsEnabled: boolean;
	feedbackId?: string;
}

export interface Pollster extends BaseUser {
	station?: number;
	role: Role.POLLSTER;
}

export type User = Client | Panelist | Pollster;
